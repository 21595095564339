function setRedirectPath() {
  const $redirectToField = document.querySelector('input#user_redirect_to');
  const $googleLoginButton = document.querySelector('a.btn--google-new');
  const $redirectToFieldForTwoFactor = document.querySelector('input#two_factor_redirect_to');
  // we respect the value coming from server-side

  if ($redirectToFieldForTwoFactor && !$redirectToFieldForTwoFactor.value) {
    $redirectToFieldForTwoFactor.setAttribute('value', window.location.hash);
  }

  if ($redirectToField && !$redirectToField.value) {
    $googleLoginButton.setAttribute(
      'href',
      `${$googleLoginButton.href}?redirect_hash=${encodeURIComponent(window.location.hash)}`,
    );
    $redirectToField.setAttribute('value', window.location.hash);
  }
}

document.onreadystatechange = () => {
  // alternative to DOMContentLoaded event
  if (document.readyState === 'interactive') {
    setRedirectPath();
  }
};
